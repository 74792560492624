import { CategoryProduct } from "./CategoryProduct";
import { ProductItem } from "./ProductItem";
import { ProductPrice } from "./ProductPrice";
import { Price } from "./Price";
import { ProductVat } from "./ProductVat";

export class Product {
    public product_id: number
    public product_extend_id: number
    public product_set_id: number
    public type: string
    public picture: string
    public order: number
    public name: string
    public code: string
    public color: string
    public product_price: ProductPrice
    public title: string
    public icon: string
    public className: string
    public association: Array<CategoryProduct>
    public items: Array<ProductItem>
    
    public designation: string
    public description: string
    
    public printer_placement: string
    public printer_bold: boolean
    public kind: string

    public product_set_assoc_id: number
    public product_extend_assoc_id: number
    
    public prices: Array<Price>
    public available?: boolean
    public favourite?: boolean

    public family_id: number
    public plu: string

    public vat: ProductVat

    constructor (
        product_id?: number, 
        type?: string, 
        name?: string, 
        code?: string,
        color?: string, 
        kind?: string, 
        picture?: string,
        order?: number,
        printer_bold: boolean = false, 
        product_price?: ProductPrice, 
        association: Array<CategoryProduct> = [], 
        items: Array<ProductItem> = [], 
        product_set_id?: number, 
        product_extend_id?: number, 
        designation?: string, 
        description?: string, 
        printer_placement?: string,
        product_extend_assoc_id?: number,
        product_set_assoc_id?: number,
        prices: Array<Price> = [],
        available: boolean = false,
        favourite: boolean = false,
        family_id?: string,
        plu?: string,
        taxRates: Array<{code: string, taxRate: number}> = []
    ) {
        this.product_id = product_id
        this.type = type
        this.kind = kind
        this.picture = picture
        this.order = order
        this.name = name
        this.color = color
        this.product_price = product_price || new ProductPrice()
        this.title = Product.findTitle(type)
        this.icon = Product.findIcon(type)
        this.className = Product.findClass(type)
        this.association = association
        this.items = items
        this.product_set_id = product_set_id
        this.product_extend_id = product_extend_id
        this.designation = designation
        this.description = description
        this.printer_placement = printer_placement
        this.printer_bold = printer_bold
        this.product_extend_assoc_id = product_extend_assoc_id
        this.product_set_assoc_id = product_set_assoc_id
        this.prices = prices
        this.available = available
        this.favourite = favourite
        this.family_id = parseInt(family_id)
        this.code = code
        this.plu = plu


        const dineringVat = taxRates.find(taxRate => taxRate.code === "DINERING")
        const orderingVat = taxRates.find(taxRate => taxRate.code === "ORDERING")
        const shippingVat = taxRates.find(taxRate => taxRate.code === "SHIPPING")

        this.vat = {
            dinering: (dineringVat) ? dineringVat.taxRate : 0,
            ordering: (orderingVat) ? orderingVat.taxRate : 0,
            shipping: (shippingVat) ? shippingVat.taxRate : 0
        }
    }

    public static create (type: string): Product {
        let name: string;
        let kind: string;
        switch (type.toUpperCase()) {
          case "SIMPLE":
            name = "Nouveau produit"
            kind = "BRUT"
            break;
          case "EXTEND":
            name = "Nouvelle composition"
            kind = "ADDITIVE"
            break;
          case "SET":
            name = "Nouvelle formule"
            kind = "SET"
            break;
          default:
            name = "Nouveau produit"
            kind = "BRUT"
            break;
        }
        return new Product(null, type.toUpperCase(), name, (type.toUpperCase() === "SIMPLE") ? "BRUT" : null, "COLOR_WHITE", kind.toUpperCase())
    }

    public static findTitle (type: string): string {
        let title: string
        switch (type) {
            case "SIMPLE":
                title = "Produits"
                break
            case "EXTEND":
                title = "Compositions"
                break
            case "SET":
                title = "Formules"
                break
            default:
                title = "Produits"
                break
        }
        return title
    }

    public static findIcon (type: string): string {
        let icon: string
        switch (type) {
            case "SIMPLE":
                icon = "shopping_basket"
                break
            case "EXTEND":
                icon = "list"
                break
            case "SET":
                icon = "assignment"
                break
            default:
                icon = "clear"
                break
        }
        return icon
    }

    public static findClass (type: string): string {
        let className: string
        switch (type) {
            case "SIMPLE":
                className = "warning"
                break
            case "EXTEND":
                className = "success"
                break
            case "SET":
                className = "info"
                break
            default:
                className = "primary"
                break
        }
        return className
    }
}