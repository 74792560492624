import { Injectable } from '@angular/core'

import { Category, Product, CategoryProduct, ProductItem, Price } from "app/models"
import { Httpclient } from './Httpclient.service';
import { Status } from 'app/models/Status';
import { ProductPrice } from 'app/models/product/ProductPrice';
import {AppConfigService} from "./AppConfigService.service";

@Injectable()
export class CategoryService {

    public endpoint: string

    constructor (
        private _Httpclient: Httpclient,
        private config:AppConfigService
    ) {
        this.endpoint = config.endpoints.category.get()
    }

    public async get (): Promise<Array<Category>> {
        try {
            let res = await this._Httpclient.get(this.endpoint)
            let categories = res.map(category => new Category(
                category.name,
                category.code,
                parseInt(category.category_id),
                [],
                (category.is_root === "TRUE"),
                category.icon_code,
                category.designation,
                category.description,
                [],
                category.kind,
                parseInt(category.order)
            )).sort((a, b) => { return (a.order === b.order) ? 0 : (a.order < b.order) ? -1 : 1 })
            return Promise.resolve(categories)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async getType (type: string): Promise<Array<CategoryProduct>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/type/${type}`)
            let categories = res.map(category => new CategoryProduct(
                category.name,
                category.code,
                category.icon_code,
                category.min,
                category.max,
                (category.product && category.product.length > 0) ? category.product
                .filter((product) => product.active === 'TRUE')
                .map(p => new Product(
                    parseInt(p.product_id), 
                    p.type, 
                    p.name, 
                    p.code,
                    p.color,
                    p.kind,
                    p.picture,
                    parseInt(p.order),
                    (category.printer_bold === "TRUE"),
                    new ProductPrice(p.product_price.ratecard_product_id,p.product_price.price_wvat)
                )) : [],
                (type === "SET") ? parseInt(category.category_set_id) : parseInt(category.category_extend_id),
                (type === "SET") ? "SET" : "EXTEND",
                parseInt(category.order),
                category.designation,
                category.description
            )).sort((a, b) => { return (a.order === b.order) ? 0 : (a.order < b.order) ? -1 : 1 })
            return Promise.resolve(categories)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async getProductByCategoryId (id: number): Promise<Array<Product>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}/product`)
            let products = res
            .filter((product) => product.active === 'TRUE')
            .map(product => new Product(
                parseInt(product.product_id), 
                product.type, 
                product.name, 
                product.code,
                product.color,
                product.kind,
                product.picture,
                parseInt(product.order),
                (product.printer_bold === "TRUE") ? true : false,
                new ProductPrice(product.product_price.ratecard_product_id, product.product_price.price_wvat),
                null,
                null,
                (product.type === "SET") ? parseInt(product.product_set_id) : null,
                (product.type === "EXTEND") ? parseInt(product.product_extend_id) : null,
                product.designation,
                product.description,
                product.printer_placement
            )).sort((a, b) => { return (a.order === b.order) ? 0 : (a.order < b.order) ? -1 : 1 })
            return Promise.resolve(products)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async getReferencesFromProductId(id: number): Promise<Array<Category>> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/reference/product/${id}`)
            let categories = res.map(category => new CategoryProduct(
                category.name,
                category.code,
                category.icon_code,
                category.min,
                category.max,
                [],
                parseInt(category.category_id),
                (category.type === "SET") ? "SET" : "EXTEND",
                parseInt(category.order),
                category.designation,
                category.description
            )).sort((a, b) => { return (a.order === b.order) ? 0 : (a.order < b.order) ? -1 : 1 })
            return categories
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async find (id: number): Promise<Category> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/${id}`)
            let category = new Category(
                res.name,
                res.code,
                parseInt(res.category_id),
                (res.product && res.product.length > 0) ? res.product
                .filter((product) => product.active === 'TRUE')
                .map(p => new Product(
                    parseInt(p.product_id), 
                    p.type, 
                    p.name, 
                    p.code,
                    p.color,
                    p.kind,
                    p.picture,
                    parseInt(p.order),
                    (res.printer_bold === "TRUE"),
                    new ProductPrice(p.product_price.ratecard_product_id, p.product_price.price_wvat)
                )) : [],
                (res.is_root === "TRUE"),
                res.icon_code,
                res.designation,
                res.description,
                (res.category && res.category.length > 0) ? res.category
                .filter((category) => category.active === 'TRUE')
                .map(c => {
                    c.category_id = parseInt(c.category_id)
                    c.order = parseInt(c.order)
                    c.iconCode = c.icon_code
                    return c
                }) : [],
                res.kind,
                parseInt(res.order),
                res.plu
            )
            return Promise.resolve(category)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async findSet (id: number): Promise<CategoryProduct> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/set/${id}`)
            let category = new CategoryProduct(
                res.name,
                res.code, 
                res.icon_code,
                res.min,
                res.max,
                (res.product && res.product.length > 0) ? res.product
                .filter((product) => product.active === 'TRUE')
                .map(p => new Product(
                    parseInt(p.product_id), 
                    p.type, 
                    p.name, 
                    p.code,
                    p.color,
                    p.kind,
                    p.picture,
                    parseInt(p.order),
                    (res.printer_bold === "TRUE"),
                    new ProductPrice(p.product_price.ratecard_product_id, p.product_price.price_wvat)
                )) : [],
                parseInt(res.category_set_id),
                "SET",
                parseInt(res.order),
                res.designation,
                res.description,
                res.plu,
                res.bogof,
                res.primary
            )
            return Promise.resolve(category)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async findExtend (id: number): Promise<CategoryProduct> {
        try {
            let res = await this._Httpclient.get(`${this.endpoint}/extend/${id}`)
            let category = new CategoryProduct(
                res.name,
                res.code,
                res.icon_code,
                res.min,
                res.max,
                (res.product && res.product.length > 0) ? res.product
                .filter((product) => product.active === 'TRUE')
                .map(p => new Product(
                    parseInt(p.product_id), 
                    p.type, 
                    p.name, 
                    p.code,
                    p.color,
                    p.kind,
                    p.picture,
                    parseInt(p.order),
                    (res.printer_bold === "TRUE"),
                    new ProductPrice(p.product_price.ratecard_product_id, p.product_price.price_wvat)
                )) : [],
                parseInt(res.category_extend_id),
                "EXTEND",
                parseInt(res.order),
                res.designation,
                res.description,
                res.plu
            )
            return Promise.resolve(category)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async add (category: Category): Promise<Status> {
        try {
            let body = {
                name: category.name,
                code: category.code,
                icon_code: category.iconCode,
                designation: category.designation,
                description: category.description,
                kind: category.kind,
                order: category.order
            }
            let res = await this._Httpclient.post(`${this.endpoint}`, body)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async copy (id: number, type: string): Promise<Status> {
        try {
            let res = await this._Httpclient.post(`${this.endpoint}/${id}/type/${type}/copy`)
            return Promise.resolve(new Status(res))
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async addSet (category: CategoryProduct): Promise<Status> {
        try {
            let body = {
                name: category.name,
                code: category.code,
                icon_code: category.iconCode,
                designation: category.designation,
                description: category.description,
                min: category.min,
                max: category.max,
                order: category.order
            }
            let res = await this._Httpclient.post(`${this.endpoint}/type/set`, body)
            if (res.category_set_id) res.lastInsertId = res.category_set_id
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async addExtend (category: CategoryProduct): Promise<Status> {
        try {
            let body = {
                name: category.name,
                code: category.code,
                icon_code: category.iconCode,
                designation: category.designation,
                description: category.description,
                min: category.min,
                max: category.max,
                order: category.order,
                kind: "ADDITIVE"
            }
            let res = await this._Httpclient.post(`${this.endpoint}/type/extend`, body)
            if (res.category_extend_id) res.lastInsertId = res.category_extend_id
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async edit (id: number, category: Category): Promise<Status> {
        try {
            let body = {
                name: category.name,
                code: category.code,
                icon_code: category.iconCode,
                designation: category.designation,
                description: category.description,
                kind: category.kind,
                order: category.order,
                plu: category.plu,
            }
            let res = await this._Httpclient.put(`${this.endpoint}/${id}`, body)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editExtend (id: number, category: CategoryProduct): Promise<Status> {
        try {
            let body = {
                code: category.code,
                name: category.name,
                designation: category.designation,
                description: category.description,
                icon_code: category.iconCode,
                min: category.min,
                max: category.max,
                plu: category.plu,
            }
            let res = await this._Httpclient.put(`${this.endpoint}/extend/${id}`, body)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editSet (id: number, category: CategoryProduct): Promise<Status> {
        try {
            let body = {
                code: category.code,
                name: category.name,
                designation: category.designation,
                description: category.description,
                icon_code: category.iconCode,
                min: category.min,
                max: category.max,
                bogof: category.bogof,
                primary: category.primary,
                plu: category.plu,
            }
            let res = await this._Httpclient.put(`${this.endpoint}/set/${id}`, body)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editCategories (id: number, categories: Array<number>): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/category`, categories)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editProducts (id: number, products: Array<number>): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/product`, products)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async editCategoryKindProducts (id: number, kind: string, products: Array<{productId: number, order: number}>): Promise<Status> {
        try {
            let res = await this._Httpclient.put(`${this.endpoint}/${id}/type/${kind}`, products)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async delete (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/${id}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async deleteExtend (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/extend/${id}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }

    public async deleteSet (id: number): Promise<Status> {
        try {
            let res = await this._Httpclient.delete(`${this.endpoint}/set/${id}`)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}