import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CommonModule, JsonPipe } from '@angular/common'
import { NgSelectModule } from '@ng-select/ng-select'
import { ColorPickerModule } from 'ngx-color-picker'
import { Ng2SmartTableModule } from 'ng2-smart-table'
import { NgxUiLoaderModule } from 'ngx-ui-loader'

import { SwitchComponent } from './switch/switch.component'
import { ToggleComponent } from './toggle/toggle.component'

import {
    MatButtonModule,
    MatInputModule,
    MatRippleModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSelectModule,
    MatDialogModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule
} from '@angular/material'

import { CardCategoryComponent } from './card/category/card.category.component'
import { CardProductComponent } from './card/product/card.product.component'
import { CardProductPriceComponent } from './card/productPrice/card.productPrice.component'
import { CardOptionComponent } from './card/option/card.option.component'
import { CardItemComponent } from './card/item/card.item.component'
import { CardCategoryExtendComponent } from './card/categoryExtend/card.categoryExtend.component'
import { CardOpeningHourComponent } from './card/openingHour/card.openingHour.component'
import { CardDeliveryModesComponent } from './card/deliveryModes/card.deliveryModes.component'
import { CardDisplayMethodsComponent } from './card/displayMethods/card.displayMethods.component'
import { CardSuggestionComponent } from './card/suggestions/card.suggestions.component'

import { TableCheckoutComponent } from './table/checkout/table.checkout.component'
import { TableStaffComponent } from './table/staff/table.staff.component'
import { TableCustomerComponent } from './table/customer/table.customer.component'
import { TableCategoryProductComponent } from './table/categoryProduct/table.categoryProduct.component'
import { TableProductionLineComponent } from './table/productionLine/table.productionLine.component'
import { TableProductionPlacementComponent } from './table/productionPlacement/table.productionPlacement.component'
import { TablePrinterComponent } from './table/printer/table.printer.component'
import { TableStockComponent } from './table/stock/table.stock.component'
import { TableProviderComponent } from './table/provider/table.provider.component'
import { TableBillingComponent } from './table/billing/table.billing.component'
import { TableInventoryComponent } from './table/inventory/table.inventory.component'
import { TableProductComponent } from './table/product/table.product.component'
import { TableSetComponent } from './table/set/table.set.component'
import { TableExtendComponent } from './table/extend/table.extend.component'
import { TableTakingsComponent } from './table/takings/table.takings.component'
import { TableUserComponent } from './table/user/table.user.component'
import { TableSuggestionsComponent } from './table/suggestions/table.suggestions.component'

import { ColorRenderComponent } from './cell/render/color.render.component'
import { CategoryRenderComponent } from './cell/render/category.render.component'
import { PasswordRenderComponent } from './cell/render/password.render.component'
import { ToggleRenderComponent } from './cell/render/toggle.render.component'
import { BillingRenderComponent } from './cell/render/billing.render.component'
import { BillingSearchRenderComponent } from './cell/render/billing.search.render.component'
import { InventorySearchRenderComponent } from './cell/render/inventory.search.render.component'
import { ProductRenderComponent } from './cell/render/product.render.component'
import { ProductColorRenderComponent } from './cell/render/productColor.render.component'
import { StockRenderComponent } from './cell/render/stock.render.component'
import { SuggestionRenderComponent } from './cell/render/suggestion.render.component'
import { StockThresholdRenderComponent } from './cell/render/stockThreshold.render.component'
import { CopyRenderComponent } from './cell/render/copy.render'

import { ColorEditorComponent } from './cell/editor/color.editor.component'
import { CategoryEditorComponent } from './cell/editor/category.editor.component'
import { PasswordEditorComponent } from './cell/editor/password.editor.component'
import { ToggleEditorComponent } from './cell/editor/toggle.editor.component'
import { BillingEditorComponent } from './cell/editor/billing.editor.component'
import { ProductColorEditorComponent } from './cell/editor/productColor.editor.component'
import { ProductEditorComponent } from './cell/editor/product.editor.component'
import { CopyEditorComponent } from './cell/editor/copy.editor.component'
import { SuggestionEditorComponent } from './cell/editor/suggestion.editor.component'

import { PasswordDialog } from './dialog/password.dialog'
import { BillingDialog } from './dialog/billing.dialog'
import { InventoryDialog } from './dialog/inventory.dialog'
import { ConfirmDialog } from './dialog/confirm.dialog'
import { ProductDialog } from './dialog/product.dialog'
import { SaveDialog } from './dialog/save.dialog'
import { ExportDialog } from './dialog/export.dialog'

import { BottomSheetSaverComponent } from './bottomSheet/saver/bottomSheet.saver.component'
import { CopyDialog } from './dialog/copy.dialog'

import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core';

@NgModule({
    imports: [
        CloudinaryModule.forRoot({Cloudinary}, { cloud_name: 'symbioz' } as CloudinaryConfiguration),
        FormsModule,
        ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
        NgSelectModule,
        Ng2SmartTableModule,
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        MatRippleModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        MatDialogModule,
        MatCheckboxModule,
        ColorPickerModule,
        MatDatepickerModule,
        MatNativeDateModule
    ],
    declarations: [
        SwitchComponent,
        ToggleComponent,

        CardCategoryComponent,
        CardProductComponent,
        CardOptionComponent,
        CardItemComponent,
        CardCategoryExtendComponent,
        CardProductPriceComponent,
        CardOpeningHourComponent,
        CardDeliveryModesComponent,
        CardDisplayMethodsComponent,
        CardSuggestionComponent,

        TableCheckoutComponent,
        TableStaffComponent,
        TableCustomerComponent,
        TableCategoryProductComponent,
        TableProductionLineComponent,
        TableProductionPlacementComponent,
        TablePrinterComponent,
        TableStockComponent,
        TableProviderComponent,
        TableBillingComponent,
        TableInventoryComponent,
        TableProductComponent,
        TableSetComponent,
        TableExtendComponent,
        TableTakingsComponent,
        TableUserComponent,
        TableSuggestionsComponent,

        ColorRenderComponent,
        CategoryRenderComponent,
        PasswordRenderComponent,
        ToggleRenderComponent,
        BillingRenderComponent,
        BillingSearchRenderComponent,
        InventorySearchRenderComponent,
        ProductRenderComponent,
        ProductColorRenderComponent,
        StockRenderComponent,
        StockThresholdRenderComponent,
        CopyRenderComponent,
        SuggestionRenderComponent,

        ColorEditorComponent,
        CategoryEditorComponent,
        PasswordEditorComponent,
        ToggleEditorComponent,
        BillingEditorComponent,
        ProductColorEditorComponent,
        ProductEditorComponent,
        CopyEditorComponent,
        SuggestionEditorComponent,

        PasswordDialog,
        BillingDialog,
        InventoryDialog,
        ConfirmDialog,
        ProductDialog,
        SaveDialog,
        ExportDialog,
        CopyDialog,

        BottomSheetSaverComponent
    ],
    entryComponents: [
        SwitchComponent,
        ToggleComponent,

        CardCategoryComponent,
        CardProductComponent,
        CardOptionComponent,
        CardItemComponent,
        CardCategoryExtendComponent,
        CardProductPriceComponent,
        CardOpeningHourComponent,
        CardDeliveryModesComponent,
        CardDisplayMethodsComponent,
        CardSuggestionComponent,

        TableCheckoutComponent,
        TableStaffComponent,
        TableCustomerComponent,
        TableCategoryProductComponent,
        TableProductionLineComponent,
        TableProductionPlacementComponent,
        TablePrinterComponent,
        TableStockComponent,
        TableProviderComponent,
        TableBillingComponent,
        TableInventoryComponent,
        TableProductComponent,
        TableSetComponent,
        TableExtendComponent,
        TableTakingsComponent,
        TableUserComponent,
        TableSuggestionsComponent,

        ColorRenderComponent,
        CategoryRenderComponent,
        PasswordRenderComponent,
        ToggleRenderComponent,
        BillingRenderComponent,
        BillingSearchRenderComponent,
        InventorySearchRenderComponent,
        ProductRenderComponent,
        ProductColorRenderComponent,
        StockRenderComponent,
        StockThresholdRenderComponent,
        CopyRenderComponent,
        SuggestionRenderComponent,

        ColorEditorComponent,
        CategoryEditorComponent,
        PasswordEditorComponent,
        ToggleEditorComponent,
        BillingEditorComponent,
        ProductColorEditorComponent,
        ProductEditorComponent,
        CopyEditorComponent,
        SuggestionEditorComponent,

        PasswordDialog,
        BillingDialog,
        InventoryDialog,
        ConfirmDialog,
        ProductDialog,
        SaveDialog,
        ExportDialog,
        CopyDialog,

        BottomSheetSaverComponent
    ],
    exports: [
        SwitchComponent,
        ToggleComponent,

        CardCategoryComponent,
        CardProductComponent,
        CardItemComponent,
        CardOptionComponent,
        CardCategoryExtendComponent,
        CardProductPriceComponent,
        CardOpeningHourComponent,
        CardDeliveryModesComponent,
        CardDisplayMethodsComponent,
        CardSuggestionComponent,
        
        TableCheckoutComponent,
        TableStaffComponent,
        TableCustomerComponent,
        TableCategoryProductComponent,
        TableProductionLineComponent,
        TableProductionPlacementComponent,
        TablePrinterComponent,
        TableStockComponent,
        TableProviderComponent,
        TableBillingComponent,
        TableInventoryComponent,
        TableProductComponent,
        TableSetComponent,
        TableExtendComponent,
        TableTakingsComponent,
        TableUserComponent,
        TableSuggestionsComponent,

        BottomSheetSaverComponent
    ]
})
export class ComponentsModule { }