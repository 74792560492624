import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Category, CategoryProduct } from 'app/models'
import { AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms'
import { CategoryService } from 'app/services'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { MatDialog } from '@angular/material'
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'card-category-extend',
    templateUrl: './card.categoryExtend.component.html',
    styleUrls: ['./card.categoryExtend.component.css']
})
export class CardCategoryExtendComponent implements OnInit {
    
    @Input() categories: Array<CategoryProduct>
    @Input() category: CategoryProduct
    @Input() index: number
    @Input() count: number
    @Input() type: string
    @Input() readonly: boolean
    @Input() cloudinaryFolder: string
    
    @Output() onEdit: EventEmitter<number> = new EventEmitter
    @Output() onRemove: EventEmitter<number> = new EventEmitter
    @Output() onChange: EventEmitter<Array<number|CategoryProduct|boolean>> = new EventEmitter
    @Output() onChangeOrder: EventEmitter<Array<number|boolean>> = new EventEmitter
    
    public create: boolean = false
    public form: FormGroup
    public code: AbstractControl
    public name: AbstractControl
    public min: AbstractControl
    public max: AbstractControl
    public iconCode: AbstractControl
    public designation: AbstractControl
    public description: AbstractControl

    public cloudinaryUrl: string

    constructor(
        private _FormBuilder: FormBuilder,
        private _CategoryService: CategoryService,
        private _LoaderService: NgxUiLoaderService,
        private _ToasterService: ToastrService,
        private _Dialog: MatDialog
    ) { }

    public ngOnInit (): void { 
        // Initialisation detail form
        this.form = this._FormBuilder.group({
            'code': [''],
            'name': ['', [Validators.required]],
            'min': ['', [Validators.required]],
            'max': ['', [Validators.required]],
            'iconCode': [''],
            'designation': [''],
            'description': ['']
        })
        this.code = this.form.controls['code']
        this.name = this.form.controls['name']
        this.min = this.form.controls['min']
        this.max = this.form.controls['max']
        this.iconCode = this.form.controls['iconCode']
        this.designation = this.form.controls['designation']
        this.description = this.form.controls['description']
console.log(this.category)
        this.cloudinaryUrl = `${this.cloudinaryFolder}/module_kiosk/Images/${this.category.iconCode}`

        this.categories.sort(this.compare)
    }

    public handleReduceOrder(): void {
        if (this.index === 0) return
        this.onChangeOrder.emit([this.index, this.category.order, this.category.order - 1, true])
    }
    public handleIncreaseOrder(): void {
        if (this.index === (this.count - 1)) return
        this.onChangeOrder.emit([this.index, this.category.order, this.category.order + 1, true])
    }
    public handleChangeOrder(): void {
        this.onChangeOrder.emit([this.index, this.category.order, this.category.order, false])
    }

    public handleEdit(): void {
        this.onEdit.emit(this.index)
    }
    public handleVoid(): void {
        return
    }
    
    public async handleRemove(): Promise<void> {
        try {
            let dialog = this._Dialog.open(ConfirmDialog, {width: '400px'})
            let result = await dialog.afterClosed().toPromise()
            if (!result) return
            this.onRemove.emit(this.index)
        } catch (error) {
            console.error("CardCategoryExtendComponent::handleRemove", error)
        }
    }
    public handleSelect(): void {
        if (!this.category.category_id) {
            this.category = new CategoryProduct(null, null, null, null, null, null, 0, this.type)
            this.create = true
        } else {
            this.create = false
            this.onChange.emit([this.index, this.categories.filter(c => c.category_id === this.category.category_id)[0]])
            this.category = Object.assign(new CategoryProduct(), this.categories.filter(c => c.category_id === this.category.category_id)[0])
        }
    }
    public handleChange(): void {
        if (this.form.valid) {
            let category = this.categories.filter((c, index) => c.category_id === this.category.category_id)[0]
            this.categories[this.categories.indexOf(category)] = this.category
            if (!this.create) this.onChange.emit([this.index,this.category])
        }
    }
    public async handleSubmit(): Promise<void> {
        try {
            if (this.form.valid) {
                this._LoaderService.start()
                let c = Object.assign(new CategoryProduct(), this.category, {type: this.category.type})
                let res = (this.category.type === "EXTEND") 
                    ? await this._CategoryService.addExtend(c)
                    : await this._CategoryService.addSet(c)
                this.category = (this.category.type === "EXTEND")
                    ? await this._CategoryService.findExtend(res.lastInsertId)
                    : await this._CategoryService.findSet(res.lastInsertId)
                this.categories.push(this.category)
                this.onChange.emit([this.index, this.category, true])
                this.create = false
                this._LoaderService.stop()
                this._ToasterService.success("Création de la catégorie effectué.")
            }
            return Promise.resolve()
        } catch (error) {
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la création de la catégorie.")
            console.error("CardCategoryComponent::handleSubmit", error, this)
        }
    }
    
    private compare( a, b ) {
        if ( a.code < b.code || a.name < b.name){
        return -1;
        }
        if ( a.code < b.code || a.name > b.name ){
        return 1;
        }
        return 0;
    }
}