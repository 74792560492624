import { Router } from '@angular/router'
import { Injectable } from '@angular/core'
import { Http, Headers } from '@angular/http'

@Injectable()
export class Httpclient {
    constructor(
        private _Http: Http,
        private _Router: Router,
    ) { }

    private createAuthorizationHeader(headers: Headers) {
        let token = sessionStorage.getItem('token') || 0
        headers.append('Authorization', `Bearer ${token}`)
        let crmToken = sessionStorage.getItem('admin-token') || 0
        headers.append('x-access-token', `Bearer ${crmToken}`)
    }

    public async get(url: string, basic?: string): Promise<any> {
        let headers = new Headers();
        if (basic) headers.append('Authorization', `Basic ${basic}`)
        else this.createAuthorizationHeader(headers)
        return this._Http.get(url, { headers })
            .toPromise()
            .then(this.intercept)
    }

    public async post(url: string, data?: any): Promise<any> {
        let headers = new Headers();
        this.createAuthorizationHeader(headers)
        return this._Http.post(url, data, { headers })
            .toPromise()
            .then(this.intercept)
    }

    public async put(url: string, data?: any): Promise<any> {
        let headers = new Headers();
        this.createAuthorizationHeader(headers)
        return this._Http.put(url, data, { headers })
            .toPromise()
            .then(this.intercept)
    }

    public async delete(url: string): Promise<any> {
        let headers = new Headers();
        this.createAuthorizationHeader(headers)
        return this._Http.delete(url, { headers })
            .toPromise()
            .then(this.intercept)
    }

    public async error(err: any): Promise<any> {
        let error: any = (err.json) ? err.json() : {}

        if (err.status === 401) {
            sessionStorage.removeItem("token")
            this._Router.navigateByUrl('/')
        }

        return Promise.reject(error || "Server error")
    }

    public async intercept(response: any): Promise<any> {
        let res = response.json()
        if (res.token) sessionStorage.setItem("token", res.token)
        return Promise.resolve(res)
    }
}