import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core'
import { Category } from 'app/models'
import { AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms'
import { CategoryService } from 'app/services'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { MatDialog } from '@angular/material'
import { ConfirmDialog } from 'app/components/dialog/confirm.dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'card-category',
    templateUrl: './card.category.component.html',
    styleUrls: ['./card.category.component.css']
})
export class CardCategoryComponent {
    
    @Input() categories: Array<Category>
    @Input() category: Category
    @Input() index: number 
    @Input() kind: string
    @Input() count: number
    @Input() cloudinaryFolder: string
    
    @Output() onEdit: EventEmitter<number> = new EventEmitter
    @Output() onRemove: EventEmitter<number> = new EventEmitter
    @Output() onChange: EventEmitter<Array<number|Category|boolean>> = new EventEmitter
    @Output() onChangeOrder: EventEmitter<Array<number|boolean>> = new EventEmitter
    
    public create: boolean = false
    public form: FormGroup
    public name: AbstractControl
    // public designation: AbstractControl
    // public description: AbstractControl

    public cloudinaryUrl: string

    constructor(
        private _FormBuilder: FormBuilder,
        private _CategoryService: CategoryService,
        private _LoaderService: NgxUiLoaderService,
        private _Dialog: MatDialog,
        private _ToasterService: ToastrService
    ) { }

    public ngOnInit(): void {
        // Initialisation detail form
        this.form = this._FormBuilder.group({
            'name': ['', [Validators.required]],
            // 'designation': [''],
            // 'description': ['']
        })
        this.name = this.form.controls['name']
        // this.designation = this.form.controls['designation']
        // this.description = this.form.controls['description']

        this.cloudinaryUrl = `${this.cloudinaryFolder}/module_kiosk/Images/${this.category.iconCode}`

        this.categories.sort(this.compare)
    }

    public handleReduceOrder(): void {
        if (this.index === 0) return
        this.onChangeOrder.emit([this.index, this.category.order, this.category.order - 1, true])
    }
    public handleIncreaseOrder(): void {
        if (this.index === (this.count - 1)) return
        this.onChangeOrder.emit([this.index, this.category.order, this.category.order + 1, true])
    }
    public handleChangeOrder(): void {
        this.onChangeOrder.emit([this.index, this.category.order, this.category.order, false])
    }

    public handleVoid(): void {
        return
    }
    public handleEdit(): void {
        this.onEdit.emit(this.index)
    }
    public handleRemove(): void {
        this.onRemove.emit(this.index)
    }
    public handleSelect(): void {
        if (!this.category.category_id) {
            this.category = new Category('', '', 0, null, false, null, null, null, null, this.kind)
            this.create = true
        } else {
            this.create = false
            this.onChange.emit([this.index, this.categories.filter(c => c.category_id === this.category.category_id)[0]])
        }
    }
    public async handleSubmit(): Promise<void> {
        try {
            if (this.form.valid) {
                this._LoaderService.start()
                let res = await this._CategoryService.add(this.category)
                this.category = await this._CategoryService.find(res.lastInsertId)
                this.categories.push(this.category)
                this.create = false
                this.onChange.emit([this.index, this.category, true])
                this._ToasterService.success("Catégorie créé.")
                this._LoaderService.stop()
            }
            return Promise.resolve()
        } catch (error) {
            this._LoaderService.stop()
            if (error.json().code === "0x1007") this._ToasterService.warning(`Vous n'êtes pas autorisé à effectuer cette action`)
            else this._ToasterService.error("Erreur lors de la création de la catégorie.")
            console.error("CardCategoryComponent::handleSubmit", error, this)
        }
    }
    
    private compare( a, b ) {
        if ( a.name < b.name ){
        return -1;
        }
        if ( a.name > b.name ){
        return 1;
        }
        return 0;
    }
}