import { Establishment } from "app/models/establishment/Establishment"
import { Vat, PrinterPlacement, Family } from "./models"

export class AppService {
    public static establishment: Establishment
    public static user: {role: string, timezone: string}
    public static userAdmin: any
    public static vats: Array<Vat>
    public static placements: Array<PrinterPlacement>
    public static families: Array<Family>
    public static currency: string
    public static cloudinaryApiKey: string

    constructor() {
        const app = { ...JSON.parse(sessionStorage.getItem("AppService")) }
        AppService.establishment = app.establishment
        AppService.user = app.user
        AppService.userAdmin = app.userAdmin
        AppService.vats = app.vats
        AppService.placements = app.placements
        AppService.families = app.families
        AppService.currency = app.currency
    }

    static cleanDatas(full = false) {
        if (full) {
            sessionStorage.removeItem("token")
            sessionStorage.removeItem("admin-token")
            sessionStorage.removeItem("AppService")
        } else {
            sessionStorage.removeItem("token")
            AppService.setUserAdmin(AppService.userAdmin)
        }
    }

    static setUserAdmin(data: any) {
        sessionStorage.setItem("AppService", JSON.stringify({
            userAdmin: data
        }))
        new this()
    }

    static setUser(data: {role: string, timezone: string}) {
        sessionStorage.setItem("AppService", JSON.stringify({
            user: data,
            userAdmin: AppService.userAdmin
        }))
        new this()
    }

    static setEstablishment(data: Establishment) {
        sessionStorage.setItem("AppService", JSON.stringify({
            establishment: data,
            user: AppService.user,
            userAdmin: AppService.userAdmin
        }))
        new this()
    }

    static setDatas(datas: {
        currency: string,
        vats: Array<Vat>,
        families: Array<Family>,
        placements: Array<PrinterPlacement>
    }) {
        sessionStorage.setItem("AppService", JSON.stringify({
            establishment: AppService.establishment,
            user: AppService.user,
            userAdmin: AppService.userAdmin,
            currency: datas.currency,
            vats: datas.vats,
            families: datas.families,
            placements: datas.placements,
        }))
        new this()
    }

    static setVats(data: Array<Vat>) {
        sessionStorage.setItem("AppService", JSON.stringify({
            establishment: AppService.establishment,
            user: AppService.user,
            userAdmin: AppService.userAdmin,
            currency: AppService.currency,
            families: AppService.families,
            placements: AppService.placements,
            vats: data,
        }))
        new this()
    }

    static setCurrency(data: string) {
        sessionStorage.setItem("AppService", JSON.stringify({
            establishment: AppService.establishment,
            user: AppService.user,
            userAdmin: AppService.userAdmin,
            families: AppService.families,
            placements: AppService.placements,
            vats: AppService.vats,
            currency: data,
        }))
        new this()
    }


    static setFamilies(data: Array<Family>) {
        sessionStorage.setItem("AppService", JSON.stringify({
            establishment: AppService.establishment,
            user: AppService.user,
            userAdmin: AppService.userAdmin,
            currency: AppService.currency,
            placements: AppService.placements,
            vats: AppService.vats,
            families: data,
        }))
        new this()
    }


    static setPlacements(data: Array<PrinterPlacement>) {
        sessionStorage.setItem("AppService", JSON.stringify({
            establishment: AppService.establishment,
            user: AppService.user,
            userAdmin: AppService.userAdmin,
            currency: AppService.currency,
            families: AppService.families,
            vats: AppService.vats,
            placements: data,
        }))
        new this()
    }
}
